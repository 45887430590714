<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")

		form(@submit.prevent="submit", v-if="placement")
			.widget
				.widget-header
					h2.title Placement Default Settings
					.buttons.pos-right
						router-link.btn.btn-default(:to="{name:'placement-list'}")
							i.la.la-angle-left
							| Back
						button.btn.btn-primary.ml-2(type="submit") Save
							i.la.la-fw.la-save

			placement-defaults(:placement="placement")


</template>
<script>
import Vue from 'vue';

export default {
  name: 'PlacementDefaultSettings',
  async beforeRouteEnter(to, from, next) {
    let placement = {};

    try {
      placement = await Vue.ovData.placement.getDefaults(true);
    } catch (e) {
      Vue.ovNotify.error('Failed to load settings');
      return next(false);
    }

    Vue.ovData.placement._booleans.forEach((f) => {
      placement[f] = !!placement[f];
    });

    next(async (vm) => {
      vm.placement = placement;
      return vm;
    });
  },
  data() {
    return {
      busy: false,
      placement: null,
    };
  },
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let data = Vue.util.extend({}, this.placement);

      Vue.ovData.placement._booleans.forEach((f) => {
        data[f] = data[f] ? 1 : 0;
      });
      try {
        await this.$ovReq.post('settings/savePlacementDefaults', data);
        this.busy = false;
        this.$ovNotify.success('Placement Defaults has been saved');
        this.$router.push({ name: 'placement-list' });
      } catch (e) {
        console.error(e);
        this.busy = false;
      }
    },
  },
};
</script>
